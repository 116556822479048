import React from "react"
import Navbar from "../navbars/marketingNavbar"
import GlobalStyles from "../styles/GlobalStyles"
import LayoutStyles from "../styles/LayoutStyles"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <LayoutStyles>
        <Navbar />
        <>
          {children}
          <footer>
            © {new Date().getFullYear()},{` `}
            Parsity
          </footer>
        </>
      </LayoutStyles>
    </>
  )
}

export default Layout
